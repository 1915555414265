import { RouteComponentProps } from '@reach/router';
import { AutoSave, Card, Form, FormInput, Icon, Stack } from 'components';
import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { FormValues, InjectedAppStore } from 'stores';

export interface WorksheetProps extends RouteComponentProps, Partial<InjectedAppStore> {}

@inject('appStore')
@observer
export class Worksheet extends React.Component<WorksheetProps> {
  render() {
    const initialValues = {
      afterRepairValue: 400000,
      capitalEx: 10,
      downPayment: 20,
      hoaFees: 0,
      grossRent: 4000,
      insurance: 500,
      interestRate: 3,
      landscaping: 0,
      loanTerm: 30,
      maintenance: 10,
      otherIncome: 0,
      propertyManagement: 10,
      propertyTaxes: 2500,
      purchaseCosts: 3,
      purchasePrice: 400000,
      rehabCosts: 0,
      rehabCostsOverrun: 10,
      utilities: 0,
      vacancy: 10,
    };

    const dollarSign = <Icon name="dollar-sign" color="blue" />;
    const percent = <Icon name="percent" color="blue" />;

    return (
      <>
        <Formik<FormValues>
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={() => {
            return (
              <Form>
                <AutoSave />
                <Stack>
                  <Card>
                    <FormInput label="Purchase Price" name="purchasePrice" prefix={dollarSign} type="text" />
                    <FormInput label="After Repair Value" name="afterRepairValue" prefix={dollarSign} type="text" />
                  </Card>
                  {/* TODO: loan section */}
                  <Card>
                    <FormInput affix={percent} label="Down Payment" name="downPayment" type="text" />
                    <FormInput affix={percent} name="interestRate" label="Interest Rate" type="text" />
                    <FormInput name="loanTerm" label="Loan Term" type="text" />
                  </Card>
                  {/* TODO: purchase section */}
                  <Card>
                    <FormInput affix={percent} name="purchaseCosts" label="Purchase Costs" type="text" />
                  </Card>
                  <Card>
                    {/* TODO: rehab section */}
                    <FormInput name="rehabCosts" label="Rehab Costs" prefix={dollarSign} type="text" />
                    <FormInput affix={percent} name="rehabCostsOverrun" label="Cost Overrun" type="text" />
                  </Card>
                  <Card>
                    {/* TODO: income section */}
                    <FormInput name="grossRent" label="Gross Rent" prefix={dollarSign} type="text" />
                    <FormInput affix={percent} name="vacancy" label="Vacancy" type="text" />
                    <FormInput name="otherIncome" label="Other Income" prefix={dollarSign} type="text" />
                  </Card>
                  <Card>
                    {/* TOOO: operating expenses */}
                    <FormInput
                      name="propertyTaxes"
                      label="Property Taxes"
                      prefix={dollarSign}
                      type="text"
                      // TODO: per year
                    />
                    <FormInput
                      name="insurance"
                      label="Insurance"
                      prefix={dollarSign}
                      type="text"
                      // TODO: per year
                    />
                    <FormInput
                      affix={percent}
                      name="propertyManagement"
                      label="Property Management"
                      type="text"
                      // TODO: percentage
                    />
                    <FormInput
                      affix={percent}
                      name="maintenance"
                      label="Maintenance"
                      type="text"
                      // TODO: percentage
                    />
                    <FormInput
                      affix={percent}
                      name="capitalEx"
                      label="Capital Expenditures"
                      type="text"
                      // TODO: percentage
                    />
                    <FormInput
                      name="hoaFees"
                      label="HOA Fees"
                      prefix={dollarSign}
                      type="text"
                      // TODO: per month
                    />
                    <FormInput
                      name="utilities"
                      label="Utilities"
                      prefix={dollarSign}
                      type="text"
                      // TODO: per month
                    />
                    <FormInput
                      name="landscaping"
                      label="Landscaping"
                      prefix={dollarSign}
                      type="text"
                      // TODO: per month
                    />
                  </Card>
                </Stack>
              </Form>
            );
          }}
        />
      </>
    );
  }

  onSubmit = (values: FormValues, actions: {}) => {
    this.props.appStore!.updateReport(values);
  };
}
