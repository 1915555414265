import './init/init';

import { Link, Router } from '@reach/router';
import { Provider } from 'mobx-react';
import * as React from 'react';
import { Home, Report, Worksheet } from 'routes';
import { AppStore } from 'stores';

export class App extends React.Component {
  private appStore: AppStore | undefined;

  constructor(props: any) {
    super(props);

    this.appStore = new AppStore();
  }

  render() {
    return (
      <Provider appStore={this.appStore}>
        <Router
          // Apparently needed to prevent router from managing focus for you
          // https://github.com/reach/router/issues/242
          primary={false}
        >
          <Home path="/" />
          <Worksheet path="/worksheet" />
          <Report path="/report" />
        </Router>
      </Provider>
    );
  }
}
