import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faBell,
  faBolt,
  faBook,
  faCaretDown,
  faCaretUp,
  faChartArea,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faClock,
  faCodeBranch,
  faCog,
  faDatabase,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faEye,
  faFile,
  faFilter,
  faFolder,
  faFont,
  faGlobe,
  faHdd,
  faHistory,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faLifeRing,
  faListUl,
  faMinusCircle,
  faPause,
  faPercent,
  faPlay,
  faPlus,
  faPlusCircle,
  faProjectDiagram,
  faSearch,
  faSitemap,
  faSpinner,
  faSquare,
  faStar,
  faStopwatch,
  faStream,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUser,
  faUserCog,
  faUserLock,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBars,
  faBell,
  faBolt,
  faBook,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faChartArea,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faClock,
  faCodeBranch,
  faCog,
  faDatabase,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faEye,
  faFile,
  faFilter,
  faFolder,
  faFont,
  faGlobe,
  faHdd,
  faHistory,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faLifeRing,
  faListUl,
  faMinusCircle,
  faPause,
  faPercent,
  faPlay,
  faPlus,
  faPlusCircle,
  faProjectDiagram,
  faSearch,
  faSitemap,
  faSpinner,
  faSquare,
  faStar,
  faStopwatch,
  faStream,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUser,
  faUserCog,
  faUserLock,
  faUsers
);
