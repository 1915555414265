// cSpell:ignore fortawesome fontawesome
import classNames from 'classnames';
import React from 'react';

// cSpell:ignore Duotone
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export type IconColor = 'blue';

/**
 * fas: Solid (default)
 * far: Regular
 * fal: Light
 * fad: Duotone
 * fab: Brand
 */
export type IconVariant = IconPrefix;

export interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  color?: IconColor;
  name?: IconName;
  variant?: IconVariant;
}

export const Icon: React.FunctionComponent<IconProps> = ({ className, color, name, variant, ...passthrough }) => {
  if (!name) {
    return null;
  }

  return (
    <FontAwesomeIcon
      icon={[variant || 'fas', name]}
      className={classNames({ 'text-blue-600': color === 'blue' })}
      {...passthrough}
    />
  );
};
