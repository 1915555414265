export function CoC(yearlyCashFlow: number, initialInvestment: number) {
  const annualCashflow = yearlyCashFlow;

  return (annualCashflow / initialInvestment) * 100;
}

export function MortgagePayment(principal: number, apr: number, loanYears: number) {
  const i = apr / 100 / 12;
  const n = loanYears * 12; // assumes monthly payment
  const t = Math.pow(i + 1, n);

  return principal * ((i * t) / (t - 1));
}

export function CapRate(noi: number, purchasePrice: number) {
  return (noi / purchasePrice) * 100;
}
