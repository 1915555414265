import { RouteComponentProps } from '@reach/router';
import classNames from 'classnames';
import { Card, ItemLine, StatBlock } from 'components';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { InjectedAppStore } from 'stores';

export interface StatementProps extends React.HTMLAttributes<HTMLElement> {
  title?: string;
}
export const Statement: React.FunctionComponent<StatementProps> = (props) => {
  const { children, className, title, ...rest } = props;

  return (
    <Card className={classNames('p-4', className)} {...rest}>
      {title ? <div>{title}</div> : null}
      {children}
    </Card>
  );
};

interface ReportProps extends RouteComponentProps, Partial<InjectedAppStore> {}

@inject('appStore')
@observer
export class Report extends React.Component<ReportProps> {
  render() {
    const {
      rCashOnCash,
      loanPayment,
      grossRent,
      vacancyExpense,
      operatingIncome,
      operatingExpenses,
      netOperatingIncome,
      cashFlow,
      purchasePrice,
      amountFinanced,
      downPayment,
      rehabCosts,
      cashNeeded,
      purchaseCosts,
      capRate,
    } = this.props.appStore!;

    return (
      <>
        <div className="flex justify-between">
          <StatBlock name="Cash needed" stat={cashNeeded} unit="$" />
          <StatBlock name="Cash flow" stat={cashFlow} unit="$" period="month" />
          <StatBlock name="Cash on cash" stat={rCashOnCash} unit="%" />
          <StatBlock name="Cap rate" stat={capRate} unit="%" />
        </div>
        <Statement title="Purchase & Rehab">
          <ItemLine item="Purchase price" value={purchasePrice} />
          <ItemLine item="Amount financed" operation="-" value={amountFinanced} />
          <ItemLine item="Down payment" operation="=" value={downPayment} />
          <ItemLine item="Purchase costs" operation="+" value={purchaseCosts} />
          <ItemLine item="Rehab costs" operation="+" value={rehabCosts} />
          <ItemLine item="Total cash needed" operation="=" value={cashNeeded} />
        </Statement>
        <Statement title="Cash flow">
          <ItemLine item="Gross rent" value={grossRent} />
          <ItemLine item="Vacancy" operation="-" value={vacancyExpense} />
          <ItemLine item="Operating Income" operation="=" value={operatingIncome} />
          <ItemLine item="Operating Expenses" operation="-" value={operatingExpenses} />
          <ItemLine item="Net Operating Income" operation="=" value={netOperatingIncome} />
          <ItemLine item="Loan Payment" operation="-" value={loanPayment} />
          <ItemLine item="Cash Flow" operation="=" value={cashFlow} />
        </Statement>
      </>
    );
  }
}
