import classNames from 'classnames';
import * as React from 'react';

export class Card extends React.Component<React.HTMLAttributes<HTMLElement>> {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classNames('border-r', 'border-b', 'border-l', 'border-t', 'border-gray-400', className)}>
        {children}
      </div>
    );
  }
}
