import classNames from 'classnames';
import { Field, FieldProps } from 'formik';
import * as React from 'react';

export interface FormInputProps {
  affix?: React.ReactNode;
  id?: string;
  label: string;
  name: string;
  prefix?: React.ReactNode;
  type: string;
}

export class FormInput extends React.Component<FormInputProps> {
  render() {
    const { label, name, type, prefix, affix } = this.props;

    const id = this.props.id || `id-${name}`;

    const Fix = ({ className, ...props }: any) => (
      <span className={classNames('absolute z-10 top-1/2 transform -translate-y-1/2', className)} {...props} />
    );

    return (
      <Field
        id={id}
        name={name}
        render={({ field, form, meta }: FieldProps) => (
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor={id}>
                {label}
              </label>
            </div>
            <div
              // className="md:w-2/3"
              className="md:w-2/3 relative"
            >
              {prefix ? <Fix className="left-2">{prefix}</Fix> : null}
              <input
                // className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                className={classNames(
                  'bg-white border border-gray-200 appearance-none w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-purple-500 rounded',
                  { 'pl-12': !!prefix, 'pr-12': !!affix }
                )}
                id={id}
                type="text"
                // FUTURE: placeholder=
                {...field}
              />
              {affix ? <Fix className="right-2">{affix}</Fix> : null}
              {/* FUTURE: {meta.touched && meta.error && meta.error} */}
            </div>
          </div>
        )}
      />
    );
  }
}
