import { Field, Form, Formik, useFormikContext } from 'formik';
import debounce from 'lodash.debounce';
import React from 'react';

export interface AutoSaveProps {
  debounceMs?: number;
}

export const AutoSave: React.FunctionComponent<AutoSaveProps> = ({ debounceMs = 1000 }) => {
  const formik = useFormikContext();
  const debouncedSubmit = React.useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [debounceMs, formik.submitForm]
  );

  React.useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return null;
};
