import classNames from 'classnames';
import { formatCurrency } from 'lib';
import React from 'react';

export interface ItemLineProps {
  item: string;
  operation?: '+' | '-' | '=';
  value?: number; // This will be more complex
}

export class ItemLine extends React.Component<ItemLineProps> {
  render() {
    const { item, operation, value } = this.props;

    const isBottom = operation === '=';
    const color: string | undefined = isBottom && typeof value === 'number' ? (value < 0 ? 'red' : 'blue') : undefined;

    return (
      <div
        className={classNames('flex', 'justify-between', {
          'border-t': isBottom,
          'border-blue-500': isBottom && color === 'blue',
          'border-red-500': isBottom && color === 'red',
          'text-blue-500': isBottom && color === 'blue',
          'text-red-500': isBottom && color === 'red',
        })}
      >
        <div className={classNames('flex-grow', {})}>{item}</div>
        <div className="flex">
          {operation ? <span>{operation}</span> : null}
          <div className="text-right" style={{ width: '100px' }}>
            {typeof value === 'number' ? `$ ${formatCurrency(value)}` : '--'}
          </div>
        </div>
      </div>
    );
  }
}
