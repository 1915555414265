import { CapRate, CoC, MortgagePayment } from 'lib';
import { action, observable } from 'mobx';

export interface FormValues {
  afterRepairValue: number;
  capitalEx: number;
  downPayment: number;
  hoaFees: number;
  grossRent: number;
  insurance: number;
  interestRate: number;
  landscaping: number;
  loanTerm: number;
  maintenance: number;
  otherIncome: number;
  propertyManagement: number;
  propertyTaxes: number;
  purchaseCosts: number;
  purchasePrice: number;
  rehabCosts: number;
  rehabCostsOverrun: number;
  utilities: number;
  vacancy: number;
}

export interface InjectedAppStore {
  appStore: AppStore;
}

export class AppStore {
  @observable
  public rCashOnCash: number | undefined;

  @observable
  public capRate: number | undefined;

  @observable
  public loanPayment: number | undefined;

  @observable
  public grossRent: number | undefined;

  @observable
  public vacancyExpense: number | undefined;

  @observable
  public operatingIncome: number | undefined;

  @observable
  public operatingExpenses: number | undefined;

  @observable
  public netOperatingIncome: number | undefined;

  @observable
  public cashFlow: number | undefined;

  @observable
  public purchasePrice: number | undefined;

  @observable
  public amountFinanced: number | undefined;

  @observable
  public downPayment: number | undefined;

  @observable
  public rehabCosts: number | undefined;

  @observable
  public cashNeeded: number | undefined;

  @observable
  public purchaseCosts: number | undefined;

  @action.bound
  public updateReport(values: FormValues) {
    const {
      grossRent,
      otherIncome,
      hoaFees,
      insurance,
      landscaping,
      maintenance,
      propertyManagement,
      propertyTaxes,
      utilities,
      vacancy,
      purchasePrice,
      downPayment,
      purchaseCosts,
      capitalEx,
      rehabCosts,
      rehabCostsOverrun,
      loanTerm,
      interestRate,
    } = values;

    this.purchasePrice = purchasePrice;
    this.downPayment = (purchasePrice * downPayment) / 100;
    this.amountFinanced = purchasePrice - this.downPayment;

    this.rehabCosts = rehabCosts * (rehabCostsOverrun / 100 + 1);

    this.purchaseCosts = (purchaseCosts / 100) * purchasePrice;

    this.cashNeeded = this.rehabCosts + this.downPayment + this.purchaseCosts;

    this.grossRent = grossRent;
    this.vacancyExpense = (grossRent * vacancy) / 100;
    this.operatingIncome = this.grossRent - this.vacancyExpense;

    const principal = purchasePrice * (1 - downPayment / 100);
    const loanPayment = MortgagePayment(principal, interestRate, loanTerm);
    this.loanPayment = loanPayment;

    this.operatingExpenses =
      propertyTaxes / 12 +
      insurance / 12 +
      grossRent * (1 - vacancy / 100) * (propertyManagement / 100) +
      grossRent * (maintenance / 100) +
      grossRent * (capitalEx / 100) +
      hoaFees +
      utilities +
      landscaping;

    const initialInvestment =
      // down payment
      purchasePrice * (downPayment / 100) +
      // closing costs
      purchasePrice * (purchaseCosts / 100) +
      //
      rehabCosts * (rehabCostsOverrun / 100 + 1);

    if (typeof this.operatingIncome === 'number' && typeof this.operatingExpenses === 'number') {
      this.netOperatingIncome = this.operatingIncome - this.operatingExpenses;
    } else {
      this.netOperatingIncome = undefined;
    }

    if (typeof this.netOperatingIncome === 'number' && typeof this.loanPayment === 'number') {
      this.cashFlow = this.netOperatingIncome - this.loanPayment;
    } else {
      this.cashFlow = undefined;
    }

    if (typeof this.cashFlow === 'number') {
      this.rCashOnCash = CoC(this.cashFlow * 12, initialInvestment);
    } else {
      this.rCashOnCash = undefined;
    }

    if (typeof this.netOperatingIncome === 'number') {
      this.capRate = CapRate(this.netOperatingIncome * 12, this.purchasePrice);
    } else {
      this.capRate = undefined;
    }
  }
}
