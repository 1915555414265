import { RouteComponentProps } from '@reach/router';
import React from 'react';

import { Report } from './Report';
import { Worksheet } from './Worksheet';

export interface HomeProps extends RouteComponentProps {}

export class Home extends React.Component<HomeProps> {
  render() {
    const Half: React.FunctionComponent = (props) => <div className="flex-grow overflow-y-auto" {...props}></div>;

    return (
      <div className="flex h-screen mx-auto" style={{ width: '1200px' }}>
        <Half>
          <Worksheet />
        </Half>
        <Half>
          <Report />
        </Half>
      </div>
    );
  }
}
