import { formatCurrency, formatPercent } from 'lib';
import numbro from 'numbro';
import React from 'react';

export interface StatBlockProps {
  name: string;
  period?: 'month' | 'year';
  unit?: '$' | '%';
  stat?: number;
}

export class StatBlock extends React.Component<StatBlockProps> {
  render() {
    const { name, period = 'year', unit = '%', stat } = this.props;

    return (
      <div className="flex flex-col align-items-center p-2">
        <span>{name}</span>
        <span>
          {unit === '$' ? '$ ' : null}
          {typeof stat === 'number' ? (unit === '%' ? formatPercent(stat) : formatCurrency(stat)) : '--'}
          {unit === '%' ? ' %' : null}
        </span>
      </div>
    );
  }
}
